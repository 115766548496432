import React from 'react';

import styles from './ProductHeader.module.css';
import { Flex, Layout } from 'antd';
import logo from '../../images/logo.png'

const ProductHeader = () => (
  <div className={styles.ProductHeader}>
  <Flex align='center' justify='center' gap={15} >
    <img src={logo} style={{maxWidth:'3rem'}}/>
    <div className={styles.wordmark}>SnipDragon</div>  </Flex>
  </div>
);



export default ProductHeader;

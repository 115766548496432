import React, { useState } from 'react';
import styles from './SnippetCard.module.css';
import { Card, Tag } from 'antd';
import {
  CopyOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  ShareAltOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Firebase CRUD operations
import { archiveSnippet, updateSnippet } from '../../utils/api';

const SnippetCard = ({ name, userId, language, description, created, modified, body, snippetId,loadSnippets }) => {
  // State to control visibility of the snippet body
  const [isBodyVisible, setIsBodyVisible] = useState(false);

  // Toggle visibility of snippet body
  const toggleBodyVisibility = () => {
    setIsBodyVisible(!isBodyVisible);
  };

  // Handle snippet deletion
  const deleteSnippet = async () => {
    try {
      await archiveSnippet(userId, snippetId);
      console.log('Snippet archived (deleted) successfully');
      loadSnippets();

      // You might want to trigger a UI update here to remove the snippet card from view
    } catch (error) {
      console.error('Error archiving snippet:', error);
    }
  };

  // Handle snippet copy to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(body);
    console.log('Snippet copied to clipboard');
  };

  // Handle snippet update
  const editSnippet = async (updatedSnippet) => {
    try {
      await updateSnippet(userId, updatedSnippet);
      console.log('Snippet updated successfully');
      // Optionally trigger UI updates here
    } catch (error) {
      console.error('Error updating snippet:', error);
    }
  };

  // Handle snippet download as a .txt file
  const downloadSnippet = () => {
    const element = document.createElement("a");
    const file = new Blob([body], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${name}.txt`; // Filename for download
    document.body.appendChild(element); // Required for this to work in Firefox
    element.click();
    console.log('Snippet downloaded');
  };

  // Handle sharing the snippet (for browsers that support the Share API)
  const shareSnippet = () => {
    if (navigator.share) {
      navigator.share({
        title: name,
        text: description,
        url: window.location.href // You can customize this to be a specific URL related to the snippet
      })
      .then(() => console.log('Snippet shared successfully'))
      .catch((error) => console.error('Error sharing snippet:', error));
    } else {
      console.log('Sharing is not supported in this browser');
    }
  };

  return (
    <div className={styles.SnippetCard}>
      <Card>
        <Card.Meta />
        <div className={styles.snippetcardholder}>
          <div>
            <Tag className={styles.snippetsubtitlegrey} bordered={false}> {language}</Tag>
          </div>
          <div className={styles.cardtitle}>{name}</div>
          <div className={styles.detailssubtitle}>
            <div>Created: {created}</div>
            <div>Modified: {modified}</div>
          </div>
          <div className={styles.subtitle}>{description}</div>
          <div className={styles.cardmenu}>
            <div onClick={toggleBodyVisibility}>
              <EyeOutlined className={styles.iconStyle} />
            </div>
            <CopyOutlined className={styles.iconStyle} onClick={copyToClipboard} />
            <EditOutlined className={styles.iconStyle} onClick={() => editSnippet()} /> {/* Pass edited snippet here */}
            <div onClick={deleteSnippet}>
              <DeleteOutlined className={styles.iconStyle} />
            </div>
            <DownloadOutlined className={styles.iconStyle} onClick={downloadSnippet} />
            <ShareAltOutlined className={styles.iconStyle} onClick={shareSnippet} />
          </div>
        </div>
        {/* Conditional rendering of the snippet body */}
        {isBodyVisible && (
          <div id={snippetId} className={styles.snippetBody}>
            <SyntaxHighlighter language={language.toLowerCase()} style={vscDarkPlus} className={styles.snippetPreview}>
              {body}
            </SyntaxHighlighter>
          </div>
        )}
      </Card>
    </div>
  );
};

export default SnippetCard;

import { getFirestore, doc, setDoc,getDocs,deleteDoc ,collection } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC4YKRECpOuFp5Q7QFLmrZrDlkLrRn0YAE",
    authDomain: "snipdragon.com",
    projectId: "snipdragon-2024",
    storageBucket: "snipdragon-2024.appspot.com",
    messagingSenderId: "827761645269",
    appId: "1:827761645269:web:10470b45120328bfad745c",
    measurementId: "G-F5T27JKD69"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);



// Update 
export async function updateSnippet(userId, snippet) {
    try {
        // Update the document in the 'snippets' collection under the user's document
        await setDoc(doc(collection(db, `users/${userId}/snippets`), snippet.id), snippet, { merge: true });
        console.log("Snippet updated successfully!");
    } catch (error) {
        console.error("Error updating snippet: ", error);
    }
}

// Delete (Archive)
export async function archiveSnippet(userId, snippetId) {
    try {
        // This is the correct way to reference the document
        await deleteDoc(doc(db, `users/${userId}/snippets`, snippetId));
        console.log("Snippet archived (deleted) successfully!");
    } catch (error) {
        console.error("Error archiving snippet: ", error);
    }
}

// Read
export async function fetchAllSnippetsByUser(userId) {
    try {
        // Get all documents from the 'snippets' collection under the user's document
        const querySnapshot = await getDocs(collection(db, `users/${userId}/snippets`));
        const snippets = [];
        querySnapshot.forEach((doc) => {
            snippets.push({ id: doc.id, ...doc.data() });
        });
        return snippets;
    } catch (error) {
        console.error("Error fetching snippets: ", error);
        return [];
    }
}
// Create
export async function addSnippetToUser(userId, snippet) {
  
    try {
        // Set the document in the 'snippets' collection under the user's document
        await setDoc(doc(collection(db, `users/${userId}/snippets`), snippet.id), snippet);
        console.log("Snippet added to Firestore successfully!");
    } catch (error) {
        console.error("Error adding snippet to Firestore: ", error);
    }
}
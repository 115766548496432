import React, { useState, useEffect, useContext } from 'react';
import styles from './App.css';
import { AppContext } from './AppContext';
import SnippetList from './components/SnippetList/SnippetList';
import ProductHeader from './components/ProductHeader/ProductHeader';
import { Flex, Layout, Input, Button, Modal, Form, Select } from 'antd';
import Login from './components/Login/Login';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { addSnippetToUser } from './utils/api';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Outlet,
  RouterProvider,
  useParams,
  useNavigate,
} from "react-router-dom";
import {generateGUID,generateGUIDWithCharacterCount} from './utils/guid'

const { Header, Footer, Content } = Layout;
const { Option } = Select;

const headerStyle = {
  textAlign: 'center',
  height: '5rem',
  padding: '1rem',
  backgroundColor: '#fff',
  justify: 'center'
};

const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#000',
  padding: '1rem',
  backgroundColor: '#fff'
};

const footerStyle = {
  textAlign: 'center',
  color: '#fff',
  backgroundColor: '#000',
};
let tempSnippetName="Snippet-" + generateGUIDWithCharacterCount(5) +"-"+ generateGUIDWithCharacterCount(6)

const layoutStyle = {};

function Home() {
  const [searchTerm, setSearchTerm] = useState('');
  const [snippets, setSnippets] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userAuthenticated, appUserID } = useContext(AppContext); // Using AppContext for auth

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const text = e.dataTransfer.getData('text/plain');
    if (text) {
      const newSnippet = {
        name: "New Snippet",
        id: generateGUIDWithCharacterCount(10),
        description: "Snippet added via drag and drop",
        created: new Date().toLocaleDateString(),
        modified: new Date().toLocaleDateString(),
        language: "Text",
        body: text
      };
      setSnippets([...snippets, newSnippet]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const addSnippet = (name, description, language, body) => {
    const newSnippet = {
      name,
      id: generateGUIDWithCharacterCount(10),
      description,
      created: new Date().toLocaleDateString(),
      modified: new Date().toLocaleDateString(),
      language,
      body,
    };
    addSnippetToUser(appUserID, newSnippet);
    setIsModalVisible(false);
  };

  const handleFormSubmit = (values) => {
    addSnippet(values.name, values.description, values.language, values.body);
  };

  return (
    <div className="App">
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
          <ProductHeader />
        </Header>
        <Content style={contentStyle}>
          <Flex vertical>
            <Login />
            {userAuthenticated && (
              <>
                <div style={{ marginBottom: '1rem' }}>
                  <Input
                    size="large"
                    placeholder="Search snippets..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ width: '100%' }}
                  />
                </div>

                <Button type="primary" className='appButtonPrimary' onClick={showModal} style={{ marginBottom: '1rem' }}>
                  + Add Snippet
                </Button>

                <SnippetList 
                  searchTerm={searchTerm}
                  onReload={isModalVisible} 
                  appUserID={appUserID}
                />
              </>
            )}
          </Flex>
        </Content>
        {appUserID &&
        <Footer style={footerStyle}>Footer</Footer>
        }
        
      </Layout>

      <Modal
        title="Add Snippet"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={handleFormSubmit}
          initialValues={{
            name:tempSnippetName ,
            description: '',
            language: 'JavaScript',
            body: '',
          }}
        >
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: 'Please input the name of the snippet!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: 'Please input the description of the snippet!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true, message: 'Please select the language of the snippet!' }]}
          >
            <Select>
              <Option value="HTML">HTML</Option>
              <Option value="JavaScript">JavaScript</Option>
              <Option value="CSS">CSS</Option>
              <Option value="Swift">Swift</Option>
              <Option value="SwiftUI">SwiftUI</Option>
              <Option value="Text">Text</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="body"
            label="Body"
            rules={[{ required: true, message: 'Please input the body of the snippet!' }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Snippet
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

const Root = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Root />}>
      <Route index element={<Home />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;